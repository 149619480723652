import { Component, inject } from '@angular/core';
import { AuthenticationService } from './authentication/service/authentication.service';
import { AppService } from '@shared/services/app.service';
import { LanguageService } from '@shared/services/language.service';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SpinnerPanelComponent } from '@shared/components/spinners/spinner-panel.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  template: `@if (loading()) { <app-spinner-panel /> }
    <router-outlet /> `,
  imports: [RouterOutlet, SpinnerPanelComponent],
})
export class AppComponent {
  isLogged: boolean = false;
  currentLang: string = 'es';

  private _app = inject(AppService);
  private _auth = inject(AuthenticationService);
  private _lang = inject(LanguageService);
  private _router = inject(Router);

  loading = this._app.loading;
  title = new Title(document);

  constructor() {
    this._app.theme.getTheme();
    this._lang.initLanguage();
    this.isLogged = this._auth.isLoggedIn;
    this._router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart ||
        event instanceof NavigationEnd ||
        event instanceof NavigationSkipped ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loading.set(event instanceof NavigationStart);
      }
    });
    this._app.appTitle.set(this.title.getTitle());
  }
}
