import { Routes } from '@angular/router';
import { authGuard } from './authentication/service/auth.guard';

export const RootRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.routes').then(
        (x) => x.AuthenticationRoutes
      ),
  },
  {
    path: '',
    canActivate: [authGuard],
    loadChildren: () => import('./home/home.routes').then((x) => x.HomeRoutes),
    data: { preload: true },
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];
