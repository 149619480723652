import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { TokenService } from './token.service';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const _translate = inject(TranslateService);
  const _toast = inject(ToastrService);
  const _auth = inject(AuthenticationService);
  const _token = inject(TokenService);
  const toastOptions = Object.freeze({
    timeOut: 4000,
    easeTime: 350,
    progressBar: true,
  });
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage =
        error.error instanceof ErrorEvent
          ? error.error.message
            ? `Error: ${error.error.message}`
            : _translate.instant('xErrorMessage')
          : error.status === 0
          ? _translate.instant('xConectionError')
          : typeof error.error === 'object'
          ? _translate.instant('xErrorMessage')
          : error.error;

      const errorCode = _translate.instant('xAdvertencia');

      if (error.status === 401) {
        errorMessage = error.error ?? _translate.instant('xSinPermisos');
        if (!_token.validateToken()) _auth.logout();
      }
      if (error instanceof HttpErrorResponse && error.status === 0) {
        _auth.logout();
      }

      _toast.warning(errorMessage, errorCode, toastOptions);
      return throwError(() => errorMessage);
    })
  );
};
