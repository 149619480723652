import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { RootRoutes } from './app.routes';
import { provideToastr } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RequestInterceptor } from './authentication/service/request.interceptor';
import { ErrorInterceptor } from './authentication/service/http-error.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { provideEnvironmentNgxMask } from 'ngx-mask';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeEsAr, 'es-Ar');

export function HttpLoaderFactory(http: HttpClient) {
  const timestamp = new Date().getTime();
  const path = '/assets/i18n/';
  return new TranslateHttpLoader(http, path, `.json?v=${timestamp}`);
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-Ar' },
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    provideRouter(RootRoutes, withPreloading(PreloadAllModules)),
    provideAnimations(),
    provideHttpClient(withInterceptors([RequestInterceptor, ErrorInterceptor])),
    provideEnvironmentNgxMask(),
    provideToastr({
      preventDuplicates: true,
    }),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
  ],
};
