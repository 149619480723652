import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const _auth = inject(AuthenticationService);
  const _router = inject(Router);

  if(_auth.isLoggedIn) {
    return true;
  }
  _router.navigate(['/auth/login']);
  return true;
};
